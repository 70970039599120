import "../css/blockimage.css";
import { motion } from "framer-motion";
import line1 from "../img/line1.png";
import line2 from "../img/line2.png";

function BlockRightImage(props) {
  return (
    <div className="wrapper-blockimage wrapper-rightimage">
      <div className="layout8">
        <div className="content padding">
          <motion.div
            className="lcontent"
            initial={{ y: 200, opacity: 0 }}
            whileInView={{
              y: 0,
              opacity: 1,
              transition: {
                type: "spring",
                stiffness: 200,
              },
            }}
          >
            {props.content}
          </motion.div>
        </div>
        <div>
          <motion.div
            initial={{ scale: 0.8, opacity: 0 }}
            whileInView={{
              scale: 1,
              opacity: 1,
              transition: {
                type: "spring",
                damping: 50,
              },
            }}
          >
            <img src={props.src} alt={props.alt}></img>
          </motion.div>
        </div>
      </div>

      <div className="bg-line1">
        <motion.div
          initial={{ scale: 0, opacity: 0 }}
          whileInView={{
            scale: 1,
            opacity: 1,
            transition: {
              type: "spring",
              damping: 50,
              duration: 1,
            },
          }}
        >
          <img className="line1" src={line1} alt="utpl"></img>
        </motion.div>
      </div>

      <div className="bg-line2">
        <motion.div
          initial={{ scale: 0, opacity: 0 }}
          whileInView={{
            scale: 1,
            opacity: 1,
            transition: {
              type: "spring",
              damping: 50,
              delay: 0.3,
              duration: 2,
            },
          }}
        >
          <img className="line2" src={line2} alt="utpl"></img>
        </motion.div>
      </div>
    </div>
  );
}

export default BlockRightImage;
