import Social from "../components/Social";
import BlockBackgroundImage from "../components/BlockBackgroundImage";
import Block2ColumnsText from "../components/Block2ColumnsText";
import Accordion from "../components/Accordion";
import { motion } from "framer-motion";

import Breadcumbs from "../components/Breadcumbs";
import { NavLink } from "react-router-dom";

import fb from "../img/social/facebook.png";
import ins from "../img/social/instagram.png";
import tk from "../img/social/tiktok.png";
import tw from "../img/social/twitter.png";
import ln from "../img/social/in.png";
import madrid from "../img/madrid.png";
import roma from "../img/roma.png";
import ny from "../img/nueva_york.png";
import quito from "../img/quito.png";
import guayaquil from "../img/guayaquil.png";
import cuenca from "../img/cuenca.png";
import redes from "../img/redes.png";
import BannerGeneral from "../components/BannerGeneral";

import lineagratuita from "../img/linea_gratuita.png";
import what from "../img/what.png";
import pbx from "../img/pbx.png";
import mail from "../img/mail.png";
import ubicacion from "../img/ubicacion.png";

import zoom from "../img/zoom.png";

function Contacto() {
  return (
    <>
      <div className="margin ">
        <BlockBackgroundImage
          src="https://utpl.edu.ec/recursos/img/portada-contacto.png"
          content={
            <>
              <h2>¡Bienvenido a</h2>
              <h2>nuestra ventana digital!</h2>
              <p>
                Si tienes preguntas, inquietudes o simplemente quieres
                saludarnos, el escenario perfecto está a un solo mensaje de
                distancia.{" "}
              </p>
            </>
          }
          id="portada"
        ></BlockBackgroundImage>
      </div>

      <Breadcumbs
        content={
          <>
            <NavLink to="/contacto">Contacto</NavLink>
          </>
        }
      ></Breadcumbs>

      <div className="lema-contacto ">
        <div className="layout6">
          <h3>
            ¡Estamos aquí para ofrecerte aún <b>más</b> respuestas, experiencias
            y conexiones!
          </h3>
          <p>
            Explora nuestras secciones de canales de atención, navega por el
            mapa de centros universitarios y únete a nuestra historia en las
            redes sociales.
          </p>{" "}
          <br></br>
        </div>
      </div>

      <div className="graybg wrapper-contactoinstitucional ">
        <div className="padding">
          <div className="section-social">
            <motion.div
              className="social"
              initial={{ x: -50, opacity: 0 }}
              whileInView={{
                x: 0,
                opacity: 1,
                transition: {
                  delay: 0.1,
                },
              }}
            >
              <img src={zoom} alt="utpl"></img>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://cedia.zoom.us/j/89419717339"
              >
                Asesoría virtual
                <p>utpl.edu.ec/zoom</p>
              </a>
            </motion.div>

            <motion.div
              className="social"
              initial={{ x: -50, opacity: 0 }}
              whileInView={{
                x: 0,
                opacity: 1,
                transition: {
                  delay: 0.1,
                },
              }}
            >
              <img src={what} alt="utpl"></img>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://wa.me/593999565400"
              >
                WhatsApp
                <p>0999565400</p>
              </a>
            </motion.div>
            <motion.div
              className="social"
              initial={{ x: -50, opacity: 0 }}
              whileInView={{
                x: 0,
                opacity: 1,
                transition: {
                  delay: 0.2,
                },
              }}
            >
              <img src={lineagratuita} alt="utpl"></img>
              <a target="_blank" rel="noreferrer" href="tel:073701444">
                Línea gratuita
                <p>1800 88 75 88</p>
              </a>
            </motion.div>
            <motion.div
              className="social"
              initial={{ x: -50, opacity: 0 }}
              whileInView={{
                x: 0,
                opacity: 1,
                transition: {
                  delay: 0.3,
                },
              }}
            >
              <img src={pbx} alt="utpl"></img>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://directorio.utpl.edu.ec"
              >
                Directorio institucional
                <p>(07) 3701444 </p>
              </a>
            </motion.div>
            <motion.div
              className="social"
              initial={{ x: -50, opacity: 0 }}
              whileInView={{
                x: 0,
                opacity: 1,
                transition: {
                  delay: 0.4,
                },
              }}
            >
              <img src={mail} alt="utpl"></img>
              <a
                target="_blank"
                rel="noreferrer"
                href="mailto:informacion@utpl.edu.ec"
              >
                Correo electrónico
                <p>informacion@utpl.edu.ec </p>
              </a>
            </motion.div>
            <motion.div
              className="social"
              initial={{ x: -50, opacity: 0 }}
              whileInView={{
                x: 0,
                opacity: 1,
                transition: {
                  delay: 0.5,
                },
              }}
            >
              <img src={ubicacion} alt="utpl"></img>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://directorio.utpl.edu.ec/centros"
              >
                Centros UTPL
                <p>Ver mapa</p>
              </a>
            </motion.div>
          </div>
        </div>

        <div>
          <iframe
            src="https://www.google.com/maps/d/embed?mid=1BgKGxSb0SBad6NEqesP-isAC22I4A2E&ehbc=2E312F"
            width="100%"
            height="600px"
          ></iframe>
        </div>
      </div>

      <div className="bluebg padding ">
        <div className="wrapper-contactocentros layout8">
          <div>
            <h2>
              Contamos con +90 centros nacionales e internacionales
              estratégicamente ubicados
            </h2>
            <p></p>
            <p>Directorio de centros UTPL</p>

            <a
              target="_blank"
              rel="noreferrer"
              href="https://directorio.utpl.edu.ec/centros"
            >
              Revísalos aquí
            </a>
          </div>
          <div className="items-centros">
            <motion.div
              initial={{ x: -50, opacity: 0 }}
              whileInView={{
                x: 0,
                opacity: 1,
                transition: {
                  delay: 0.1,
                },
              }}
            >
              <img src={madrid} alt="utpl"></img>
              <div className="layout9">
                <h3> UTPL Centro Madrid</h3>
                <p>Ronda de Segovia, 50</p>
                <p>cimadrid@utpl.edu.ec</p>
                <p>(+34) 912618144</p>
              </div>
            </motion.div>
            <motion.div
              initial={{ x: -50, opacity: 0 }}
              whileInView={{
                x: 0,
                opacity: 1,
                transition: {
                  delay: 0.2,
                },
              }}
            >
              <img src={roma} alt="utpl"></img>
              <div className="layout9">
                <h3> UTPL Centro Roma</h3>
                <p>Via dei Giubbonari 64, CAP:00186 Roma (Italia).</p>
                <p>ciroma@utpl.edu.ec</p>
                <p>(+39) 3381120872</p>
              </div>
            </motion.div>
            <motion.div
              initial={{ x: -50, opacity: 0 }}
              whileInView={{
                x: 0,
                opacity: 1,
                transition: {
                  delay: 0.3,
                },
              }}
            >
              <img src={ny} alt="utpl"></img>
              <div className="layout9">
                <h3> UTPL Centro Nueva York</h3>
                <p>
                  8658 Midland Parkway, Jamaica Estates, N.Y. 11432, Nueva York,
                  Estados Unidos
                </p>
                <p>cinewyork@utpl.edu.ec</p>
                <p>(+718) 7390155</p>
              </div>
            </motion.div>
            <motion.div
              initial={{ x: -50, opacity: 0 }}
              whileInView={{
                x: 0,
                opacity: 1,
                transition: {
                  delay: 0.4,
                },
              }}
            >
              <img src={quito} alt="utpl"></img>
              <div className="layout9">
                <h3> UTPL Centro Quito</h3>
                <p> Av. 6 de diciembre y Alpallana </p>
                <p> crquito@utpl.edu.ec </p>
                <p>(02) 3947220</p>
              </div>
            </motion.div>
            <motion.div
              initial={{ x: -50, opacity: 0 }}
              whileInView={{
                x: 0,
                opacity: 1,
                transition: {
                  delay: 0.5,
                },
              }}
            >
              <img src={guayaquil} alt="utpl"></img>

              <div className="layout9">
                <h3> UTPL Centro Guayaquil</h3>
                <p>Av. Kennedy 333 entre Av. San Jorge y calle F</p>
                <p>informacioncrg@utpl.edu.ec</p>
                <p>(04) 2282084</p>
                <p> (04) 2282085</p>
              </div>
            </motion.div>
            <motion.div
              initial={{ x: -50, opacity: 0 }}
              whileInView={{
                x: 0,
                opacity: 1,
                transition: {
                  delay: 0.6,
                },
              }}
            >
              <img src={cuenca} alt="utpl"></img>
              <div className="layout9">
                <h3> UTPL Centro Cuenca</h3>
                <p>Av. Gran Colombia 22-167 y Unidad Nacional </p>
                <p> crcuenca@utpl.edu.ec</p>
                <p> (07) 3708000 ext 7021</p>
              </div>
            </motion.div>
          </div>
        </div>
      </div>

      <div className=" graybg section-contactosocial">
        <div className="layout8 wrapper-contactosocial">
          <div>
            <img src={redes} alt="utpl"></img>
          </div>

          <div>
            <motion.h2
              initial={{ y: 200, opacity: 0 }}
              whileInView={{
                y: 0,
                opacity: 1,
              }}
            >
              <b>Conecta con nosotros</b> a través de nuestras{" "}
              <b>redes sociales oficiales</b>
            </motion.h2>

            <motion.div
              className="wrapper-social"
              initial={{ x: -50, opacity: 0 }}
              whileInView={{
                x: 0,
                opacity: 1,
                transition: {
                  delay: 0.3,
                },
              }}
            >
              <Social></Social>
            </motion.div>
          </div>
        </div>

        <div className="others-social padding layout6">
          <div className="layout9">
            <center>
              <h2>
                ¡Tu historia con nosotros se expande a través de múltiples
                canales!
              </h2>
              <p>
                Descubre más historias, actualizaciones y momentos
                espectaculares a través de nuestras diversas redes sociales.{" "}
              </p>
            </center>

            <Accordion
              title="Redes sobre oferta académica"
              content={
                <>
                  <div className="redes-oferta">
                    <div>
                      <p>
                        <b>En línea y distancia</b>
                      </p>

                      <div className="flex">
                        <a href="https://www.instagram.com/utplenlinea/">
                          <img src={ins} alt="utpl"></img>
                        </a>
                        <a href="https://www.facebook.com/utplenlinea/">
                          <img src={fb} alt="utpl"></img>
                        </a>
                        <a href="https://www.tiktok.com/@utplenlinea">
                          <img src={tk} alt="utpl"></img>
                        </a>
                      </div>
                    </div>
                    <div>
                      <p>
                        <b>Presencial</b>
                      </p>

                      <div className="flex">
                        <a href="https://www.facebook.com/utplpresencial/">
                          <img src={fb} alt="utpl"></img>
                        </a>
                      </div>
                    </div>
                    <div>
                      <p>
                        <b>UTPL TEC</b>
                      </p>

                      <div className="flex">
                        <a href="https://www.instagram.com/utpltec/">
                          <img src={ins} alt="utpl"></img>
                        </a>
                        <a href="https://www.facebook.com/utpltec">
                          <img src={fb} alt="utpl"></img>
                        </a>
                        <a href="https://www.tiktok.com/@utpltec">
                          <img src={tk} alt="utpl"></img>
                        </a>{" "}
                        <a href="https://www.linkedin.com/company/utpl-tec/">
                          <img src={ln} alt="utpl"></img>
                        </a>
                      </div>
                    </div>
                    <div>
                      <p>
                        <b>Maestrías</b>
                      </p>
                      <div className="flex">
                        <a href="https://www.instagram.com/utplposgrados/">
                          <img src={ins} alt="utpl"></img>
                        </a>
                        <a href="https://www.facebook.com/utplposgrados">
                          <img src={fb} alt="utpl"></img>
                        </a>
                      </div>
                    </div>

                    <div>
                      <p>
                        <b>Formación Permanente</b>
                      </p>
                      <div className="flex">
                        <a href="https://www.instagram.com/formacionpermanenteutpl/">
                          <img src={ins} alt="utpl"></img>
                        </a>
                        <a href="https://www.facebook.com/formacionpermanenteutpl">
                          <img src={fb} alt="utpl"></img>
                        </a>
                        <a href="https://twitter.com/PermanenteUtpl">
                          <img src={tw} alt="utpl"></img>
                        </a>
                      </div>
                    </div>

                    <div>
                      <p>
                        <b>EDES</b>
                      </p>
                      <div className="flex">
                        <a href="https://www.instagram.com/edes_ecuador/">
                          <img src={ins} alt="utpl"></img>
                        </a>
                        <a href="https://www.facebook.com/EDESBusinessSchool">
                          <img src={fb} alt="utpl"></img>
                        </a>
                      </div>
                    </div>

                    <div>
                      <p>
                        <b>ESCOP</b>
                      </p>
                      <div className="flex">
                        <a href="https://www.instagram.com/escoputpl/">
                          <img src={ins} alt="utpl"></img>
                        </a>
                        <a href="https://www.facebook.com/EscopUTPL">
                          <img src={fb} alt="utpl"></img>
                        </a>
                      </div>
                    </div>
                  </div>
                </>
              }
            />
            <Accordion
              title="Redes sobre acciones universitarias"
              content={
                <>
                  <div className="redes-oferta">
                    <div>
                      <p>
                        <b>Misiones Universitarias</b>
                      </p>
                      <div className="flex">
                        <a href="https://www.instagram.com/utplmisiones/">
                          <img src={ins} alt="utpl"></img>
                        </a>
                        <a href="https://www.facebook.com/utplmisiones">
                          <img src={fb} alt="utpl"></img>
                        </a>
                        <a href="https://twitter.com/utplmisiones">
                          <img src={tw} alt="utpl"></img>
                        </a>
                      </div>
                    </div>

                    <div>
                      <p>
                        <b>Alumni</b>
                      </p>
                      <div className="flex">
                        <a href="https://www.instagram.com/utplALUMNI/">
                          <img src={ins} alt="utpl"></img>
                        </a>
                        <a href="https://www.facebook.com/UTPLAlumni">
                          <img src={fb} alt="utpl"></img>
                        </a>
                        <a href="https://twitter.com/UTPLAlumni">
                          <img src={tw} alt="utpl"></img>
                        </a>
                      </div>
                    </div>

                    <div>
                      <p>
                        <b>Feutpl</b>
                      </p>
                      <div className="flex">
                        <a href="https://www.instagram.com/FEUTPL/">
                          <img src={ins} alt="utpl"></img>
                        </a>
                        <a href="https://www.facebook.com/feutpl">
                          <img src={fb} alt="utpl"></img>
                        </a>
                      </div>
                    </div>

                    <div>
                      <p>
                        <b>Global Campus</b>
                      </p>
                      <div className="flex">
                        <a href="https://www.instagram.com/globalcamp_utpl/">
                          <img src={ins} alt="utpl"></img>
                        </a>
                        <a href="https://twitter.com/GlobalCampUTPL">
                          <img src={tw} alt="utpl"></img>
                        </a>
                      </div>
                    </div>

                    <div>
                      <p>
                        <b>Investigación </b>
                      </p>
                      <div className="flex">
                        <a href="https://www.facebook.com/InvestigaUTPL/">
                          <img src={ins} alt="utpl"></img>
                        </a>
                        <a href="https://twitter.com/investigautpl">
                          <img src={tw} alt="utpl"></img>
                        </a>
                      </div>
                    </div>

                    <div>
                      <p>
                        <b>Vinculación</b>
                      </p>
                      <div className="flex">
                        <a href="https://twitter.com/VinculacionUtpl">
                          <img src={tw} alt="utpl"></img>
                        </a>
                      </div>
                    </div>

                    <div>
                      <p>
                        <b>Catedras Unesco</b>
                      </p>
                      <div className="flex">
                        <a href="https://www.instagram.com/catedrasunescoutpl/">
                          <img src={ins} alt="utpl"></img>
                        </a>
                      </div>
                    </div>

                    <div>
                      <p>
                        <b>Prendho</b>
                      </p>
                      <div className="flex">
                        <a href="https://www.instagram.com/prendho/">
                          <img src={ins} alt="utpl"></img>
                        </a>
                        <a href="https://www.facebook.com/prendho">
                          <img src={fb} alt="utpl"></img>
                        </a>
                        <a href="https://twitter.com/prendho">
                          <img src={tw} alt="utpl"></img>
                        </a>
                      </div>
                    </div>

                    <div>
                      <p>
                        <b>Eventos</b>
                      </p>
                      <div className="flex">
                        <a href="https://www.instagram.com/EVENTOSUTPL/">
                          <img src={ins} alt="utpl"></img>
                        </a>
                        <a href="https://www.facebook.com/utpleventos">
                          <img src={fb} alt="utpl"></img>
                        </a>
                      </div>
                    </div>

                    <div>
                      <p>
                        <b>Gestión cultural</b>
                      </p>
                      <div className="flex">
                        <a href="https://www.instagram.com/gestionculturalutpl/">
                          <img src={ins} alt="utpl"></img>
                        </a>
                        <a href="https://www.facebook.com/GestionCulturalUTPL">
                          <img src={fb} alt="utpl"></img>
                        </a>
                      </div>
                    </div>

                    <div>
                      <p>
                        <b>Biblioteca</b>
                      </p>
                      <div className="flex">
                        <a href="https://www.instagram.com/bibliotecautpl/">
                          <img src={ins} alt="utpl"></img>
                        </a>
                        <a href="https://www.facebook.com/BibliotecaUTPL.Loja">
                          <img src={fb} alt="utpl"></img>
                        </a>
                        <a href="https://twitter.com/BibliotecaUTPL">
                          <img src={tw} alt="utpl"></img>
                        </a>
                      </div>
                    </div>

                    <div>
                      <p>
                        <b>Radio UTPL</b>
                      </p>
                      <div className="flex">
                        <a href="https://www.instagram.com/radioutpl/">
                          <img src={ins} alt="utpl"></img>
                        </a>
                        <a href="https://twitter.com/radioutpl">
                          <img src={tw} alt="utpl"></img>
                        </a>
                      </div>
                    </div>
                  </div>
                </>
              }
            />
          </div>
        </div>
      </div>

      <BannerGeneral></BannerGeneral>
    </>
  );
}

export default Contacto;
