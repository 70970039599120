import "../css/floating.css";
import image1 from "../img/wha.webp";
import { motion } from "framer-motion";
function Floating() {
  return (
    <motion.div
      className="wrapper-floating"
      initial={{ y: 200, opacity: 0 }}
      whileInView={{
        y: 0,
        opacity: 1,
        transition: {
          type: "spring",
          stiffness: 400,
          delay:0.3,
        },
      }}
      whileHover={{  scale:1.1}}
      
    >
      <a href="https://wa.me/593999565400" target="_blank" rel="noreferrer">
        <img src={image1} alt="utpl"></img>
      </a>
    </motion.div>
  );
}

export default Floating;
