import BannerGeneral from "../components/BannerGeneral";
import { NavLink } from "react-router-dom";
import Breadcumbs from "../components/Breadcumbs";
import BlockRightImage from "../components/BlockRightImage";

import BlockLeftImage from "../components/BlockLeftImage";

import Accordion from "../components/Accordion";

import {
  DistanciaContinuos,
  PresencialInscripcion,
  PresencialNuevos,
  PresencialContinuos,
  TecnologiasContinuos,
  PosgradoPostulacion,
  PosgradoNuevos,
  DistanciaNuevos,
} from "../components/Pasos";

function Matricula() {
  return (
    <>
      <div className="wrapper-matricula margin">
        <Breadcumbs
          content={
            <>
              <NavLink to="/matriculas">Matricúlate ahora</NavLink>
            </>
          }
        ></Breadcumbs>

        <div className="layout6">
          <center>
            <h2>
              <b>¡Matricúlate ahora</b> y descubre todo lo que necesitas saber
              sobre los enlaces de registro, fechas, procesos y calendario
              académico adaptado a tu programa de estudios!{" "}
            </h2>
            <p>
              <b>Ya seas un estudiante nuevo o de segundo ciclo en adelante,</b>{" "}
              aquí encontrarás todo lo necesario para planificar tu periodo
              académico.
            </p>
          </center>
        </div>
        <div className="padding2">
          <BlockLeftImage
            src="https://utpl.edu.ec/recursos/img/linea.gif"
            alt="utpl"
            content={
              <>
                <h3>
                  Carreras <b>en línea y a distancia </b>
                </h3>

                <h4>
                  ¿Estás <b>interesado en estudiar con nosotros?</b>
                </h4>
                <a href="https://matriculas.utpl.edu.ec">Matricúlate ahora</a>
                <DistanciaNuevos></DistanciaNuevos>
                <h4>
                  ¿Eres estudiante de <b>segundo ciclo en adelante?</b>
                </h4>
                <a
                  target="_blank"
                  href="https://studentssb-prod.srv.utpl.edu.ec/StudentSelfService"
                >
                  Matricúlate ahora
                </a>
                <DistanciaContinuos></DistanciaContinuos>
              </>
            }
          ></BlockLeftImage>
          <div className="layout8">
            <Accordion
              title="Fechas de matrícula"
              content={
                <>
                  <p>
                    <strong>Periodo octubre 2024 - febrero 2025</strong>
                  </p>

                  <p>
                    <strong>
                      Matrículas ordinarias (alumnos nuevos y reingresos):{" "}
                    </strong>
                    7 de junio al 2 de octubre de 2024
                  </p>

                  <p>
                    <strong>Matrículas ordinarias (alumnos continuos): </strong>
                    14 de agosto al 2 de octubre de 2024
                  </p>

                  <p>
                    <strong>
                      Matrículas extraordinarias (alumnos nuevos, continuos y
                      reingresos):{" "}
                    </strong>
                    3 al 6 de octubre de 2024
                  </p>

                  <p>
                    <strong>Inicio de actividades académicas: </strong>14 de
                    octubre de 2024
                  </p>

                  <p>
                    <strong>Evaluaciones primer bimestre</strong>: 6 al 10 de
                    diciembre de 2024
                  </p>

                  <p>
                    <strong>Evaluaciones segundo bimestre:</strong> 31 de enero
                    al 4 de febrero de 2024
                  </p>

                  <p>
                    <strong>Recuperación:</strong> 22 y 23 de febrero de 2025
                  </p>
                </>
              }
            ></Accordion>

            <Accordion
              title="Calendario académico"
              content={
                <>
                  <p>
                    <a
                      target="_blank"
                      href="https://utpl.sharepoint.com/sites/Repositorioinstitucionaldecalendariosacadmicos.Estudiantes/Repositorio%20calendarios/Forms/AllItems.aspx?id=%2Fsites%2FRepositorioinstitucionaldecalendariosacadmicos%2EEstudiantes%2FRepositorio%20calendarios%2FPeriodo%20anual%20octubre%202023%20%2D%20septiembre%202024%2FPeriodo%20semestral%20abril%202024%20%2D%20agosto%202024%2FCalendario%20Acad%C3%A9mico%20Grado%2FModalidad%20a%20distancia%2FCalendario%20acad%C3%A9mico%5F202462%2Epdf&parent=%2Fsites%2FRepositorioinstitucionaldecalendariosacadmicos%2EEstudiantes%2FRepositorio%20calendarios%2FPeriodo%20anual%20octubre%202023%20%2D%20septiembre%202024%2FPeriodo%20semestral%20abril%202024%20%2D%20agosto%202024%2FCalendario%20Acad%C3%A9mico%20Grado%2FModalidad%20a%20distancia"
                    >
                      Periodo abril - agosto 2024
                    </a>
                  </p>{" "}
                </>
              }
            ></Accordion>
          </div>
        </div>

        <div className="graybg padding2">
          <BlockRightImage
            src="https://utpl.edu.ec/recursos/img/presencial.gif"
            alt="utpl"
            content={
              <>
                <h3>
                  Carreras <b>Presencial</b>
                </h3>

                <a href="https://prapps.utpl.edu.ec/ords/utpl_crm/r/registro-utpl/login">
                  <b>Inscríbete ahora a la prueba de admisión </b>
                </a>

                <PresencialInscripcion></PresencialInscripcion>

                <h4>
                  ¿Fuiste admitido como <b>estudiante nuevo?</b>
                </h4>

                <a
                  target="_blank"
                  href="https://studentssb-prod.srv.utpl.edu.ec/StudentSelfService"
                >
                  Matricúlate ahora
                </a>
                <PresencialNuevos></PresencialNuevos>
                <h4>
                  ¿Eres estudiante de <b>segundo ciclo en adelante?</b>
                </h4>

                <a
                  target="_blank"
                  href="https://studentssb-prod.srv.utpl.edu.ec/StudentSelfService"
                >
                  Matricúlate ahora
                </a>
                <PresencialContinuos></PresencialContinuos>
              </>
            }
          ></BlockRightImage>
          <div className="layout8">
            <Accordion
              title="Fechas de matrícula"
              content={
                <>
                  <p>
                    <b>
                      <u>Periodo octubre 2024 - febrero 2025</u>
                    </b>{" "}
                  </p>

                  <p>
                    <b>Inscríbete</b> a la prueba de admisión{" "}
                    <b>hasta el 7 de agosto</b>
                  </p>
                  <p>
                    <b> Confirmación de cupo para alumnos nuevos: </b>según notificación enviada al estudiante

                  </p>
                  <p>
                    <b>
                      Matrículas ordinarias (estudiantes continuos y
                      reingreso):
                    </b> desde el 29 de agosto al 2 de octubre de 2024
                  </p>
                  <p>
                    <b>
                      Matrículas extraordinarias (estudiantes continuos y
                      reingreso):
                    </b> desde el 3 al 6 de octubre de 2024
                  </p>
                  <p>
                    <b>
                      Inicio de actividades académicas para la carrera de
                      Medicina:
                    </b> 30 de septiembre de 2024
                  </p>
                  <p>
                    <b>
                      Inicio de actividades académicas para resto de
                      titulaciones:
                    </b> 14 de octubre de 2024
                  </p>

                  <br></br>
                  <p>
                    <b>
                      <u>Periodo extraordinario julio-septiembre 2024</u>
                    </b>
                  </p>

                  <ul>
                    <li>
                      <p>
                        <b>Matrícula: </b>del 15 al 26 de julio de 2024
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>Inicio de actividades académicas: </b>29 de julio de
                        2024.
                      </p>
                    </li>
                  </ul>

                  <p>
                    <b>Oferta</b>
                  </p>

                  <ul>
                    <li>
                      <p>
                        <a href="https://www.utpl.edu.ec/documentos/pe2024/salud.pdf">
                          Ciencias de la Salud
                        </a>
                      </p>{" "}
                    </li>{" "}
                    <li>
                      <p>
                        <a href="https://www.utpl.edu.ec/documentos/pe2024/economicas.pdf">
                          Ciencias Económicas y Empresariales
                        </a>
                      </p>
                    </li>
                    <li>
                      <p>
                        <a href="https://www.utpl.edu.ec/documentos/pe2024/exactas.pdf">
                          Ciencias Exactas y Naturales
                        </a>
                      </p>
                    </li>
                    <li>
                      <p>
                        <a href="https://www.utpl.edu.ec/documentos/pe2024/sociales.pdf">
                          Ciencias Sociales, Educación y Humanidades
                        </a>
                      </p>
                    </li>
                    <li>
                      <p>
                        <a href="https://www.utpl.edu.ec/documentos/pe2024/juridicas.pdf">
                          Ciencias Jurídicas y Políticas
                        </a>
                      </p>
                    </li>
                    <li>
                      <p>
                        <a href="https://www.utpl.edu.ec/documentos/pe2024/ingenierias.pdf">
                          Ingenierías y Arquitectura
                        </a>
                      </p>
                    </li>
                  </ul>
                </>
              }
            ></Accordion>
            <Accordion
              title="Calendario académico"
              content={
                <>
                  <p>
                    <a
                      target="_blank"
                      href="https://www.utpl.edu.ec/carreras/sites/default/files/Calendario%20acad%C3%A9mico_202462%20%281%29.pdf"
                    >
                      Periodo abril - agosto 2024, todas las carreras
                    </a>
                  </p>
                  <p>
                    <a
                      target="_blank"
                      href="https://www.utpl.edu.ec/carreras/sites/default/files/Calendario%20acad%C3%A9mico%20medicina_202462.pdf"
                    >
                      Periodo abril - agosto 2024, medicina
                    </a>
                  </p>
                  <p>
                    <a
                      target="_blank"
                      href="https://www.utpl.edu.ec/carreras/sites/default/files/Calendario%20acad%C3%A9mico_Anual_MED%20ENF%20NUT_P_GR_202411.pdf"
                    >
                      Periodo mayo 2024 - abril 2025 - medicina, enfermería,
                      nutrición y dietética
                    </a>
                  </p>

                  <p>
                    <a
                      target="_blank"
                      href="https://www.utpl.edu.ec/documentos/calendario_periodo_extraordinario_julio_agosto_2024.pdf"
                    >
                      Periodo extraordinario julio-septiembre 2024
                    </a>
                  </p>
                </>
              }
            ></Accordion>
          </div>
        </div>
        <div className="padding2">
          {" "}
          <BlockLeftImage
            src="https://utpl.edu.ec/recursos/img/tecnologias.gif"
            alt="utpl"
            content={
              <>
                <h3>
                  Carreras <b>Tecnológicas</b>
                </h3>

                <h4>
                  ¿Estás <b>interesado en estudiar con nosotros?</b>
                </h4>
                <a href="https://matriculas.utpl.edu.ec">Matricúlate ahora</a>
                <DistanciaNuevos></DistanciaNuevos>
                <h4>
                  ¿Eres estudiante de <b>segundo ciclo en adelante?</b>
                </h4>
                <a
                  target="_blank"
                  href="https://studentssb-prod.srv.utpl.edu.ec/StudentSelfService"
                >
                  Matricúlate ahora
                </a>
                <TecnologiasContinuos></TecnologiasContinuos>
              </>
            }
          ></BlockLeftImage>
          <div className=" layout8">
            <Accordion
              title="Fechas de matrícula"
              content={
                <>
                  <p>
                    <strong>Periodo octubre 2024 - febrero 2025</strong>
                  </p>

                  <p>
                    <strong>
                      Matrículas ordinarias (alumnos nuevos y reingresos):{" "}
                    </strong>
                    7 de junio al 2 de octubre de 2024
                  </p>

                  <p>
                    <strong>Matrículas ordinarias (alumnos continuos): </strong>
                    14 de agosto al 2 de octubre de 2024
                  </p>

                  <p>
                    <strong>
                      Matrículas extraordinarias (alumnos nuevos, continuos y
                      reingresos):{" "}
                    </strong>
                    3 al 6 de octubre de 2024
                  </p>

                  <p>
                    <strong>Inicio de actividades académicas: </strong>14 de
                    octubre de 2024
                  </p>

                  <p>
                    <strong>Evaluaciones primer bimestre</strong>: 6 al 10 de
                    diciembre de 2024
                  </p>

                  <p>
                    <strong>Evaluaciones segundo bimestre:</strong> 31 de enero
                    al 4 de febrero de 2024
                  </p>

                  <p>
                    <strong>Recuperación:</strong> 22 y 23 de febrero de 2025
                  </p>
                </>
              }
            ></Accordion>

            <Accordion
              title="Calendario académico"
              content={
                <>
                  {" "}
                  <>
                    <p>
                      <a
                        target="_blank"
                        href="https://utpl.sharepoint.com/sites/Repositorioinstitucionaldecalendariosacadmicos.Estudiantes/Repositorio%20calendarios/Forms/AllItems.aspx?id=%2Fsites%2FRepositorioinstitucionaldecalendariosacadmicos%2EEstudiantes%2FRepositorio%20calendarios%2FPeriodo%20anual%20octubre%202023%20%2D%20septiembre%202024%2FPeriodo%20semestral%20abril%202024%20%2D%20agosto%202024%2FCalendario%20Acad%C3%A9mico%20Tecnolog%C3%ADa%2FCalendario%20acad%C3%A9mico%5FD%5FTEC%5F202462%2Epdf&parent=%2Fsites%2FRepositorioinstitucionaldecalendariosacadmicos%2EEstudiantes%2FRepositorio%20calendarios%2FPeriodo%20anual%20octubre%202023%20%2D%20septiembre%202024%2FPeriodo%20semestral%20abril%202024%20%2D%20agosto%202024%2FCalendario%20Acad%C3%A9mico%20Tecnolog%C3%ADaa"
                      >
                        Periodo abril - agosto 2024
                      </a>
                    </p>{" "}
                  </>
                </>
              }
            ></Accordion>
          </div>
        </div>

        <div className="graybg padding2">
          <BlockRightImage
            src="https://utpl.edu.ec/recursos/img/posgrados.gif"
            alt="utpl"
            content={
              <>
                <h3>
                  <b>Posgrados</b>
                </h3>

                <a href="https://prapps.utpl.edu.ec/ords/utpl_crm/r/registro-utpl/login">
                  <b>Postula ahora </b>
                </a>
                <PosgradoPostulacion></PosgradoPostulacion>

                <h4>
                  ¿Fuiste admitido como <b>estudiante nuevo?</b>{" "}
                </h4>

                <a
                  target="_blank"
                  href="https://studentssb-prod.srv.utpl.edu.ec/StudentSelfService"
                >
                  Matricúlate ahora
                </a>
                <PosgradoNuevos></PosgradoNuevos>
              </>
            }
          ></BlockRightImage>
          <div className="layout8">
            <Accordion
              title="Fechas de matrícula"
              content={
                <>
                  <p>
                    <strong>Periodo octubre 2024 - febrero 2025</strong>
                  </p>

                  <p>
                    <strong>
                      Matrículas ordinarias (alumnos nuevos y reingresos):{" "}
                    </strong>
                    7 de junio al 2 de octubre de 2024
                  </p>

                  <p>
                    <strong>Matrículas ordinarias (alumnos continuos): </strong>
                    14 de agosto al 2 de octubre de 2024
                  </p>

                  <p>
                    <strong>
                      Matrículas extraordinarias (alumnos nuevos, continuos y
                      reingresos):{" "}
                    </strong>
                    3 al 6 de octubre de 2024
                  </p>

                  <p>
                    <strong>Inicio de actividades académicas: </strong>14 de
                    octubre de 2024
                  </p>

                  <br></br>
                  <p>
                    <strong>Periodo abril - agosto 2025</strong>
                  </p>

                  <p>
                    <strong>
                      Matrículas ordinarias (alumnos nuevos y reingresos):{" "}
                    </strong>
                    19 de noviembre de 2024 al 1 de abril de 2025
                  </p>

                  <p>
                    <strong>Matrículas ordinarias (alumnos continuos): </strong>
                    14 de febrero al 1 de abril de 2025
                  </p>

                  <p>
                    <strong>
                      Matrículas extraordinarias (alumnos nuevos, continuos y
                      reingresos):{" "}
                    </strong>
                    2 y 3 de abril de 2025
                  </p>

                  <p>
                    <strong>Inicio de actividades académicas: </strong>7 de
                    abril de 2024
                  </p>
                </>
              }
            ></Accordion>
            <Accordion
              title="Calendario académico"
              content={
                <>
                  <>
                    <p>
                      <a
                        target="_blank"
                        href="https://utpl.sharepoint.com/:b:/r/sites/Repositorioinstitucionaldecalendariosacadmicos.Estudiantes/Repositorio%20calendarios/Periodo%20anual%20octubre%202023%20-%20septiembre%202024/Periodo%20semestral%20abril%202024%20-%20agosto%202024/Calendario%20Acad%C3%A9mico%20Posgrado/Calendario%20academico_P_PS_202462.pdf?csf=1&web=1&e=DWtBYo"
                      >
                        Periodo abril - agosto 2024, presencial
                      </a>
                      <a
                        target="_blank"
                        href="https://utpl.sharepoint.com/:b:/r/sites/Repositorioinstitucionaldecalendariosacadmicos.Estudiantes/Repositorio%20calendarios/Periodo%20anual%20octubre%202023%20-%20septiembre%202024/Periodo%20semestral%20abril%202024%20-%20agosto%202024/Calendario%20Acad%C3%A9mico%20Posgrado/Calendario%20academico_D-EL_PS_202462.pdf?csf=1&web=1&e=r5S3Rd"
                      >
                        Periodo abril - agosto 2024, en línea y a distancia
                      </a>
                    </p>{" "}
                  </>
                </>
              }
            ></Accordion>
          </div>
        </div>
      </div>

      <BannerGeneral></BannerGeneral>
    </>
  );
}

export default Matricula;
