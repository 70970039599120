import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import NewHome from "./pages/newHome";
import Oferta from "./pages/Oferta";
import Floating from "./components/Floating";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Acercade from "./pages/Acercade";
import Transparencia from "./pages/Transparencia";
import Rendicion from "./pages/Rendicion";
import Contacto from "./pages/Contacto";
import ModeloDistancia from "./pages/ModeloDistancia";
import Presencial from "./pages/Presencial";
import ModeloPresencial from "./pages/ModeloPresencial";
import Enlinea from "./pages/Enlinea";
import Herramientas from "./pages/Herramientas";
import Tecnologias from "./pages/Tecnologias";
import Posgrado from "./pages/Posgrado";
import FormacionPermanente from "./pages/FormacionPermanente";
import Matricula from "./pages/Matricula";
import NewGobernanza from "./pages/NewGobernanza";
import Decidesermas from "./pages/Decidesermas";
import Imagen from "./pages/Imagen";
import RoutesPosgrados from "./pages/posgrados/RoutesPosgrados";
import Alumni from "./pages/Alumni";

import { ApolloProvider } from "@apollo/client";
import client from "./hooks/apolloClient";

import "./index.css";
import Gem from "./pages/landings/Gem";
import Validacioninternacional from "./pages/landings/ValidacionInternacional";
import ParqueCientifico from "./pages/ParqueCientifico";
import Calidad from "./pages/Calidad";
import PruebaAdmision from "./pages/PruebaAdmision";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter basename="/">
      <Floating></Floating>
      <Header></Header>
      <Routes>
        <Route index element={<NewHome></NewHome>} />
        <Route path="/oferta" element={<Oferta />} />
        <Route path="/matriculas" element={<Matricula />} />
        <Route path="/decidesermas" element={<Decidesermas />} />
        <Route path="/alumni" element={<Alumni />} />
        <Route path="/imagen" element={<Imagen />} />
        <Route path="/acercade" element={<Acercade />} />
        <Route path="/gobernanza" element={<NewGobernanza />} />
        <Route path="/transparencia" element={<Transparencia />} />
        <Route path="/rendicioncuentas" element={<Rendicion />} />
        <Route path="/contacto" element={<Contacto />} />
        <Route path="/presencial" element={<Presencial />} />
        <Route path="/estudiar-en-loja" element={<ModeloPresencial />} />
        <Route path="/en-linea" element={<Enlinea />} />
        <Route path="/estudiar-en-linea" element={<ModeloDistancia />} />
        <Route path="/herramientas" element={<Herramientas />} />
        <Route path="/tecnologias" element={<Tecnologias />} />
        <Route path="/posgrados" element={<Posgrado />} />
        <Route path="/formacionpermanente" element={<FormacionPermanente />} />
        <Route path="/gem" element={<Gem />} />
        <Route path="/parque-cientifico" element={<ParqueCientifico />} />
        <Route path="/validacion-internacional" element={<Validacioninternacional />} />
        <Route path="/calidad" element={<Calidad />} />
        <Route path="/presencial/prueba" element={<PruebaAdmision />} />
      </Routes>
      <ApolloProvider client={client}>
        <RoutesPosgrados></RoutesPosgrados>
      </ApolloProvider>
      <Footer></Footer>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
