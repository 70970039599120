import { useState } from "react";
import { NavLink } from "react-router-dom";
import QuickItems from "./QuickItems";
import "../css/menu.css";

function Menu() {
  const [statemenu, setStateMenu] = useState();
  const [state, setState] = useState(false);

  return (
    <>
      <span
        className="material-icons menu-title"
        onClick={(e) => setState(true)}
      >
        menu
      </span>

      {state && (
        <div className="wrapper-menu">
          <span
            className="material-icons menu-close"
            onClick={(e) => setState(false)}
          >
            close
          </span>

          <div className="section-menu layout8">
            <div className="items-menu">
              <div className="title">
                <div
                  className={statemenu === 1 && "active"}
                  onClick={(e) => setStateMenu(1)}
                >
                  Oferta académica
                  <span class="material-icons">chevron_right</span>
                </div>
              </div>

              <div className="options-menu">
                {statemenu === 1 && (
                  <div>
                    <p>
                      <b>Carreras de grado:</b>
                    </p>

                    <div className="layout9">
                      <NavLink
                        to="/presencial"
                        onClick={(e) => setState(false)}
                      >
                        Modalidad presencial
                        <span class="material-icons">chevron_right</span>
                      </NavLink>

                      <NavLink to="/en-linea" onClick={(e) => setState(false)}>
                        Modalidad a distancia y en línea
                        <span class="material-icons">chevron_right</span>
                      </NavLink>
                    </div>
                    <NavLink to="/tecnologias" onClick={(e) => setState(false)}>
                      Carreras tecnológicas
                      <span class="material-icons">chevron_right</span>
                    </NavLink>
                    <NavLink to="/posgrados" onClick={(e) => setState(false)}>
                      Posgrados
                      <span class="material-icons">chevron_right</span>
                    </NavLink>

                    <NavLink
                      to="/formacionpermanente"
                      onClick={(e) => setState(false)}
                    >
                      Formación permanente
                      <span class="material-icons">chevron_right</span>
                    </NavLink>
                  </div>
                )}
              </div>

              <div className="title">
                <div
                  className={statemenu === 2 && "active"}
                  onClick={(e) => setStateMenu(2)}
                >
                  Acerca de <span class="material-icons">chevron_right</span>
                </div>
              </div>


              <div className="options-menu">
                {statemenu === 2 && (
                  <div>
                    <NavLink to="/acercade" onClick={(e) => setState(false)}>
                      Acerca de
                      <span class="material-icons">chevron_right</span>
                    </NavLink>
                    <NavLink to="/gobernanza" onClick={(e) => setState(false)}>
                      Gobernanza
                      <span class="material-icons">chevron_right</span>
                    </NavLink>
                    <NavLink to="/imagen" onClick={(e) => setState(false)}>
                      Imagen
                      <span class="material-icons">chevron_right</span>
                    </NavLink>

                    <a
                      className="intranet"
                      href="https://utpl.hiringroom.com/jobs"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Trabaja con nosotros
                      <span class="material-icons">chevron_right</span>
                    </a>
                  </div>
                )}
              </div>
              
              <div className="title">
                <NavLink to="/transparencia" onClick={(e) => setState(false)}>
                  Transparencia{" "}
                  <span class="material-icons">chevron_right</span>
                </NavLink>
              </div>

              <div className="title">
                <div
                  className={statemenu === 3 && "active"}
                  onClick={(e) => setStateMenu(3)}
                >
                  Explora además
                  <span class="material-icons">chevron_right</span>
                </div>
              </div>

              <div className="options-menu">
                {statemenu === 3 && (
                  <>
                    <div>
                      <NavLink to="/alumni" onClick={(e) => setState(false)}>
                        UTPL Alumni
                        <span class="material-icons">chevron_right</span>
                      </NavLink>
                      <a
                        href="https://biblioteca.utpl.edu.ec/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Biblioteca
                        <span class="material-icons">chevron_right</span>
                      </a>{" "}
                      <a
                        href="https://eventos.utpl.edu.ec/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Eventos
                        <span class="material-icons">chevron_right</span>
                      </a>
                      <a
                        href="https://ilfam.utpl.edu.ec/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        ILFAM
                        <span class="material-icons">chevron_right</span>
                      </a>{" "}
                      <a
                        href="https://utpl.edu.ec/internacional"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Internacional
                        <span class="material-icons">chevron_right</span>
                      </a>
                      <a
                        href="https://investigacion.utpl.edu.ec/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Investigación
                        <span class="material-icons">chevron_right</span>
                      </a>{" "}
                      <a
                        href="https://utpl.edu.ec/misiones"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Misiones Universitarias
                        <span class="material-icons">chevron_right</span>
                      </a>{" "}
                      <a
                        href="https://noticias.utpl.edu.ec"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Noticias
                        <span class="material-icons">chevron_right</span>
                      </a>
                      <a
                        href="https://parquecientifico.utpl.edu.ec/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Parque Científico
                        <span class="material-icons">chevron_right</span>
                      </a>
                      <a
                        href="https://vinculacion.utpl.edu.ec/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Vinculación
                        <span class="material-icons">chevron_right</span>
                      </a>
                    </div>
                  </>
                )}
              </div>

              <div className="title">
                <NavLink to="/contacto" onClick={(e) => setState(false)}>
                  Contacto <span class="material-icons">chevron_right</span>
                </NavLink>
              </div>
            </div>

            {/* 
            <div className="form">
              <div className="wrapper-blockformutpl ">
                <div className="layout8">
                  <h3>Solicita información</h3>
                  <div className="formutpl">
                    <HubspotForm
                      portalId="3056987"
                      formId="09e4a84a-50f8-45d6-a81f-8c4eab9a82be"
                      css=""
                    />
                  </div>
                </div>
              </div>
            </div>*/}
          </div>

          <QuickItems></QuickItems>
        </div>
      )}
    </>
  );
}

export default Menu;
