import Breadcumbs from "../components/Breadcumbs";
import { NavLink } from "react-router-dom";
import useContentful from "../hooks/use-contenful";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import BlockRightImage from "../components/BlockRightImage";
import BlockLeftImage from "../components/BlockLeftImage";
import DataTable from "react-data-table-component";
import Accordion from "../components/Accordion";
import bg_mas from "../img/mas.png";
import { motion } from "framer-motion";

const query = `
query 
{
    videoTemplate(id: "2hS4jkDstDUcDmoVmokdsT") {
    imageBlock1{url}
      contentBlock1{json}
      contentBlock2{json}
      contentBlock3{json}
         contentBlock4{json}
               contentBlock5{json}
      
    }
  
}
`;

const columns1 = [
  {
    name: "Agencia",
    selector: (row) => row.agencia,
    sortable: true,
    wrap: true,
  },
  {
    name: "Fecha de acreditación",
    selector: (row) => row.year,
    sortable: true,
    maxWidth: "200px",
  },
  {
    name: "Denominación",
    selector: (row) => row.denominacion,
    sortable: true,
    maxWidth: "150px",
  },
  {
    name: "Duración",
    selector: (row) => row.duracion,
    sortable: true,
    maxWidth: "120px",
  },
  {
    name: "Estado",
    selector: (row) => row.estado,
    sortable: true,
    maxWidth: "120px",
  },
];

const columns2 = [
  {
    name: "Facultad",
    selector: (row) => row.facultad,
    sortable: true,
    wrap: true,
    minWidth: "150px",
  },
  {
    name: "Carrera",
    selector: (row) => row.carrera,
    sortable: true,
    minWidth: "120px",
    wrap: true,
  },
  {
    name: "Modalidad",
    selector: (row) => row.modalidad,
    sortable: true,
    maxWidth: "100px",
    wrap: true,
  },
  {
    name: "Agencia",
    selector: (row) => row.agencia,
    sortable: true,
    minWidth: "200px",
    wrap: true,
  },
  {
    name: "País",
    selector: (row) => row.pais,
    sortable: true,
    maxWidth: "100px",
    wrap: true,
  },

  {
    name: "Fecha",
    selector: (row) => row.fecha,
    sortable: true,
    minWidth: "100px",
    wrap: true,
  },
  {
    name: "Denominación",
    selector: (row) => row.denominacion,
    sortable: true,
    maxWidth: "120px",
    wrap: true,
  },
  {
    name: "Alcance",
    selector: (row) => row.alcance,
    sortable: true,
    minWidth: "120px",
    wrap: true,
  },
  {
    name: "Duración",
    selector: (row) => row.duracion,
    sortable: true,
    maxWidth: "90px",
    wrap: true,
  },
  {
    name: "Estado",
    selector: (row) => row.estado,
    sortable: true,
    maxWidth: "90px",
    wrap: true,
  },
];
const columns3 = [
  {
    name: "Facultad",
    selector: (row) => row.facultad,
    sortable: true,
    wrap: true,
  },
  {
    name: "Posgrado",
    selector: (row) => row.posgrado,
    sortable: true,
    wrap: true,
  },
  {
    name: "Modalidad",
    selector: (row) => row.modalidad,
    sortable: true,
    maxWidth: "150px",
  },
  {
    name: "Agencia",
    selector: (row) => row.agencia,
    sortable: true,

    wrap: true,
  },
  {
    name: "País",
    selector: (row) => row.pais,
    sortable: true,
    maxWidth: "120px",
  },
  {
    name: "Alcance",
    selector: (row) => row.alcance,
    sortable: true,
    maxWidth: "120px",
  },
  {
    name: "Estado",
    selector: (row) => row.estado,
    sortable: true,
    maxWidth: "120px",
  },
];

const datatable1 = [
  {
    id: 1,
    agencia:
      "Consejo de Evaluación, Acreditación y Aseguramiento de la Calidad de la Educación Superior - CEAACES",
    year: "26 de noviembre de 2013",
    denominacion: "Acreditada",
    duracion: "5 años",
    estado: "Expirado",
  },
  {
    id: 2,
    agencia:
      "Consejo de Aseguramiento de la Calidad de la Educación Superior - CACES",
    year: "25 de octubre de 2020",
    denominacion: "Acreditada",
    duracion: "5 años",
    estado: "Vigente",
  },
];

const datatable2 = [
  {
    id: 1,
    facultad: "Facultad de Ingenierías y Arquitectura",
    carrera: "Arquitectura",
    modalidad: "Presencial",
    agencia:
      " Acreditadora Nacional de Programas de Arquitectura y Disciplinas del Espacio Habilitante, A.C. ",
    pais: "México",
    fecha: "Septiembre 2015",
    denominacion: "Acreditada",
    alcance: "Internacional",
    duracion: "5 años",
    estado: "Expirado",
  },

  {
    id: 2,
    facultad: "Facultad de Ingenierías y Arquitectura",
    carrera: "Electrónica y Telecomunicaciones",
    modalidad: "Presencial",
    agencia:
      " Consorcio Iberoamericano para la Educación en Ciencia y Tecnología",
    pais: "México",
    fecha: "Diciembre 2010",
    denominacion: "Certificada",
    alcance: "Internacional",
    duracion: "2 años",
    estado: "Expirado",
  },
  {
    id: 3,
    facultad: "Facultad de Ingenierías y Arquitectura",
    carrera: "Ingeniería Civil",
    modalidad: "Presencial",
    agencia: "Consejo Nacional de Ciencias de la Ingeniería",
    pais: "México",
    fecha: "Febrero 2012",
    denominacion: "Acreditada",
    alcance: "Internacional",
    duracion: "2 años",
    estado: "Expirado",
  },

  {
    id: 4,
    facultad: "Facultad de Ciencias Sociales, Educación y Humanidades",
    carrera: "Comunicación Social",
    modalidad: "Presencial",
    agencia:
      "Consejo Latinoamericano de Acreditación de la Educación en Comunicación y Periodismo",
    pais: "México/Argentina",
    fecha: "Julio 2016",
    denominacion: "Reacreditada",
    alcance: "Internacional",
    duracion: "6 años",
    estado: "Expirado",
  },
  {
    id: 5,
    facultad: "Facultad de Ciencias Jurídicas y Políticas",
    carrera: "Derecho",
    modalidad: "A distancia",
    agencia: "Sello de Calidad Kalos Virtual Iberoamérica",
    pais: "México",
    fecha: "Septiembre 2022",
    denominacion: "Acreditada",
    alcance: "Internacional",
    duracion: "6 años",
    estado: "Vigente",
  },
  {
    id: 6,
    facultad: "Facultad de Ciencias Jurídicas y Políticas",
    carrera: "Derecho",
    modalidad: "A distancia",
    agencia: "Consejo de Aseguramiento de la Calidad de la Educación Superior",
    pais: "Ecuador",
    fecha: "Agosto 2022",
    denominacion: "Acreditada",
    alcance: "Nacional",
    duracion: "5 años",
    estado: "Vigente",
  },
  {
    id: 7,
    facultad: "Facultad de Ciencias Jurídicas y Políticas",
    carrera: "Derecho",
    modalidad: "Presencial",
    agencia: "Consejo de Aseguramiento de la Calidad de la Educación Superior",
    pais: "Ecuador",
    fecha: "Febrero 2018",
    denominacion: "Acreditada",
    alcance: "Nacional",
    duracion: "5 años",
    estado: "Vigente",
  },
  {
    id: 8,
    facultad: "Facultad de Ciencias Económicas y Empresariales",
    carrera: "Contabilidad y Auditoría",
    modalidad: "Presencial",
    agencia:
      "Consejo de Acreditación en la Enseñanza de la Contaduría y Administración, A. C.",
    pais: "México",
    fecha: "Junio 2018",
    denominacion: "Reacreditada",
    alcance: "Internacional",
    duracion: "5 años",
    estado: "Expirado",
  },
  {
    id: 9,
    facultad: "Facultad de Ciencias Económicas y Empresariales",
    carrera: "Economía",
    modalidad: "Presencial",
    agencia:
      "Consejo Nacional para la Acreditación de la Ciencia Económica, A. C.",
    pais: "México",
    fecha: "Enero 2017",
    denominacion: "Reacreditada",
    alcance: "Internacional",
    duracion: "5 años",
    estado: "Expirado",
  },
  {
    id: 10,
    facultad: "Facultad de Ciencias Económicas y Empresariales",
    carrera: "Administración de Empresas",
    modalidad: "Presencial",
    agencia:
      "Consejo de Acreditación en la Enseñanza de la Contaduría y Administración, A. C.",
    pais: "México",
    fecha: "Noviembre 2012",
    denominacion: "Acreditada",
    alcance: "Internacional",
    duracion: "5 años",
    estado: "Expirado",
  },
  {
    id: 11,
    facultad: "Facultad de Ciencias Económicas y Empresariales",
    carrera: "Administración en Banca y Finanzas",
    modalidad: "Presencial",
    agencia:
      "Consejo de Acreditación en la Enseñanza de la Contaduría y Administración, A. C.",
    pais: "México",
    fecha: "Junio 2015",
    denominacion: "Acreditada",
    alcance: "Internacional",
    duracion: "5 años",
    estado: "Expirado",
  },
  {
    id: 12,
    facultad: "Facultad de Ciencias Exactas y Naturales",
    carrera: "Ingeniería Química",
    modalidad: "Presencial",
    agencia: "Agencia Acreditadora de Chile Acreditación y Calidad",
    pais: "Chile",
    fecha: "Marzo 2021",
    denominacion: "Acreditada",
    alcance: "Internacional",
    duracion: "5 años",
    estado: "Vigente",
  },
  {
    id: 13,
    facultad: "Facultad de Ciencias Exactas y Naturales",
    carrera: "Ingeniería Química",
    modalidad: "Presencial",
    agencia: "Consejo de Aseguramiento de la Calidad de Educación Superior",
    pais: "Ecuador",
    fecha: "Marzo 2021",
    denominacion: "Acreditada",
    alcance: "Nacional",
    duracion: "5 años",
    estado: "Vigente",
  },
  {
    id: 14,
    facultad: "Facultad de Ciencias Exactas y Naturales",
    carrera: "Bioquímica y Farmacia",
    modalidad: "Presencial",
    agencia: "Agencia Acreditadora de Chile Acreditación y Calidad",
    pais: "Chile",
    fecha: "Marzo 2021",
    denominacion: "Acreditada",
    alcance: "Internacional",
    duracion: "5 años",
    estado: "Vigente",
  },
  {
    id: 15,
    facultad: "Facultad de Ciencias Exactas y Naturales",
    carrera: "Bioquímica y Farmacia",
    modalidad: "Presencial",
    agencia: "Consejo de Aseguramiento de la Calidad de Educación Superior",
    pais: "Ecuador",
    fecha: "Marzo 2021",
    denominacion: "Acreditada",
    alcance: "Nacional",
    duracion: "5 años",
    estado: "Vigente",
  },
  {
    id: 16,
    facultad: "Facultad de Ciencias Exactas y Naturales",
    carrera: "Ingeniero en Alimentos",
    modalidad: "Presencial",
    agencia: "Agencia Acreditadora de Chile Acreditación y Calidad",
    pais: "México",
    fecha: "Marzo 2021",
    denominacion: "Acreditada",
    alcance: "Internacional",
    duracion: "5 años",
    estado: "Vigente",
  },
  {
    id: 17,
    facultad: "Facultad de Ciencias Exactas y Naturales",
    carrera: "Ingeniero en Alimentos",
    modalidad: "Presencial",
    agencia: "Consejo de Aseguramiento de la Calidad de Educación Superior",
    pais: "Ecuador",
    fecha: "Marzo 2021",
    denominacion: "Acreditada",
    alcance: "Nacional",
    duracion: "5 años",
    estado: "Vigente",
  },
  {
    id: 18,
    facultad: "Facultad de Ciencias de la Salud",
    carrera: "Medicina",
    modalidad: "Presencial",
    agencia: "Consejo de Aseguramiento de la Calidad de Educación Superior",
    pais: "Ecuador",
    fecha: "Octubre 2019",
    denominacion: "Acreditada",
    alcance: "Nacional",
    duracion: "5 años",
    estado: "Vigente",
  },
  {
    id: 19,
    facultad: "Facultad de Ciencias de la Salud",
    carrera: "Enfermería",
    modalidad: "Presencial",
    agencia: "Agencia Acreditadora de Chile Acreditación y Calidad",
    pais: "Chile",
    fecha: "",
    denominacion: "",
    alcance: "Internacional",
    duracion: "",
    estado: "En proceso",
  },
];

const datatable3 = [
  {
    id: 1,
    facultad: "Facultad de Ciencias de la Salud",
    posgrado: "Gerencia de Instituciones de Salud",
    modalidad: "a Distancia",
    agencia: "Agencia Acreditadora de Chile Acreditación & Calidad ",
    pais: "Chile",
    alcance: "Internacional",
    estado: "En proceso",
  },
  {
    id: 2,
    facultad: "Facultad de Ciencias de la Salud",
    posgrado: "Gestión de la Calidad y Auditoría en Salud",
    modalidad: "a Distancia",
    agencia: "Agencia Acreditadora de Chile Acreditación & Calidad ",
    pais: "Chile",
    alcance: "Internacional",
    estado: "En proceso",
  },
  {
    id: 3,
    facultad: "Ciencias de la Salud",
    posgrado: "Análisis Biológico y Diagnóstico de Laboratorio",
    modalidad: "En línea",
    agencia: "Agencia Acreditadora de Chile Acreditación & Calidad ",
    pais: "Chile",
    alcance: "Internacional",
    estado: "En proceso",
  },
  {
    id: 4,
    facultad: "Ciencias Jurídicas y Políticas",
    posgrado: "Derecho, mención Derecho Procesal",
    modalidad: "En línea",
    agencia: "Agencia Acreditadora de Chile Acreditación & Calidad ",
    pais: "Chile",
    alcance: "Internacional",
    estado: "En proceso",
  },
  {
    id: 5,
    facultad: "Ciencias Sociales, Educación y Humanidades",
    posgrado: "Educación, mención Innovación y Liderazgo Educativo",
    modalidad: "En línea",
    agencia: "Agencia Acreditadora de Chile Acreditación & Calidad ",
    pais: "Chile",
    alcance: "Internacional",
    estado: "En proceso",
  },
  {
    id: 6,
    facultad: "Ciencias Sociales, Educación y Humanidades",
    posgrado: "Educación, mención Enseñanza de la Matemática",
    modalidad: "En Línea",
    agencia: "Agencia Acreditadora de Chile Acreditación & Calidad ",
    pais: "Chile",
    alcance: "Internacional",
    estado: "En proceso",
  },

  {
    id: 7,
    facultad: "Escuela de Desarrollo Empresarial y Social",
    posgrado: "Administración de Empresas, mención Innovación",
    modalidad: "Presencial, A distancia",
    agencia: "Education Quality Accreditation Agency",
    pais: "Perú",
    alcance: "Internacional",
    estado: "En proceso",
  },
];

function Calidad() {
  let { data, errors } = useContentful(query);
  if (errors)
    return <span>{errors.map((error) => error.message).join(",")}</span>;
  if (!data) return <p>Loading...</p>;

  const mystyle = {
    backgroundImage: `url(${process.env.PUBLIC_URL + bg_mas})`,
  };

  return (
    <>
      <div className="margin">
        <Breadcumbs
          content={
            <>
              <NavLink to="/transparencia">Transparencia</NavLink>
              <span class="material-icons">chevron_right</span>
              <NavLink to="/calidad">Calidad</NavLink>
            </>
          }
        ></Breadcumbs>
      </div>
      <BlockLeftImage
        src={data.videoTemplate.imageBlock1.url}
        content={
          <>
            {documentToReactComponents(data.videoTemplate.contentBlock1.json)}
            <Accordion
              title="Documentos institucionales"
              content={
                <>
                  {documentToReactComponents(
                    data.videoTemplate.contentBlock2.json
                  )}
                </>
              }
            ></Accordion>
          </>
        }
      ></BlockLeftImage>

      <div
        className="bluebg padding wrapper-evaluacion-institucional"
        style={mystyle}
      >
        <div className="layout8 icon100">
          <motion.div
            initial={{ y: 200, opacity: 0 }}
            whileInView={{
              y: 0,
              opacity: 1,
              transition: {
                type: "spring",
                stiffness: 200,
              },
            }}
          >
            <div className="width700 ">
              {documentToReactComponents(data.videoTemplate.contentBlock3.json)}
            </div>
          </motion.div>
          <div className="table-calidad">
            <DataTable columns={columns1} data={datatable1} />
          </div>
        </div>
      </div>

      <div className=" graybg padding">
        <div className="layout9">
          <center>
            <motion.div
              initial={{ y: 200, opacity: 0 }}
              whileInView={{
                y: 0,
                opacity: 1,
                transition: {
                  type: "spring",
                  stiffness: 200,
                },
              }}
            >
              <div className="width700 ">
                {documentToReactComponents(
                  data.videoTemplate.contentBlock4.json
                )}
              </div>
            </motion.div>
          </center>
          <div className="table-calidad">
            <DataTable columns={columns2} data={datatable2} pagination />
          </div>
        </div>
      </div>

      <div className=" padding ">
        <div className="layout9">
          <center>
            <motion.div
              initial={{ y: 200, opacity: 0 }}
              whileInView={{
                y: 0,
                opacity: 1,
                transition: {
                  type: "spring",
                  stiffness: 200,
                },
              }}
            >
              <div className="width700 ">
                {documentToReactComponents(
                  data.videoTemplate.contentBlock5.json
                )}
              </div>
            </motion.div>
          </center>
          <div className="table-calidad">
            <DataTable columns={columns3} data={datatable3} pagination />
          </div>
        </div>
      </div>
    </>
  );
}

export default Calidad;
